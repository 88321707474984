.card {
    opacity: 10;
    transform: translateY(30px);
    transition: opacity 2s ease-out, transform 2s ease-out;
  }

  .cardd {
    opacity: 1;
    transform: translateX(20px);
    transition: opacity 2s ease-in, transform 8s ease-in;
  }
  
  .card.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .colour {
    background: linear-gradient(to top, #fba61b 50%, #003ecb 50%);
  }
  
  .card-container {
    width: 100%;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .card-container:hover {
  /* opacity: 10%; */
  transition: width 0.1s, height 0.1s, transform 0.1s;
  }
  