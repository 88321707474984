/* ------------ CSS for all the file in this project--------------*/

.body {
    background: #003ecb;
    color: aliceblue;
    height:100%;
    width: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.text-blue {
    color: #003ecb;
}

.colour {
    background: linear-gradient(to top, #fba61b 50%, #003ecb 50%);
  }

html, body {
    margin: 0;
    height: 100%;
}

.abcd {
    background: #fba61b;
    padding: 0%;
}

.circleBase {
    border-radius: 50%; /* remove if you don't care about IE8 */
}

.error {
    max-width: 100%;
    height: 50%;
    width: 50px; /* ie8 */
}

.type3 {
    width: 200px;
    height: 200px;
    line-height: 200px;
    align-items: center;
    background: rgb(255, 255, 255);
    text-align: center;
    border: 5px solid #003ecb;
}

.container1 {
    width: 100%;

}

.type3:hover {
    border: 5px solid #fba61b;
}

.image {
    height: 250px;
    width: 250px;
    resize: both;
}

.name {
    font-size: 70px;
    font-weight: 900;
    color: #fba61b;
}

.header {
    background-color: #fba61b;
}

.btn-custom {
    background-color: #fba61b;
    font-weight: bolder;
    color: #ffffff;
}

.btn-custom:hover {
    background-color: #003ecb;
    font-weight: bolder;
    color: #fba61b;
    border: 2px solid #fba61b;
}

.text-yellow {
    color: #fba61b;
}

.abbb {
    justify-content: center;
}


.row1 {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    color: black;
    width: 100%;
    
}

.column1 {
    flex: 20%; /* Four columns in a row */
    max-width: 25%;
    overflow: hidden;
    word-wrap: break-word;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: #ffffff;
    margin: 20px;
    text-align: center;
    border-radius: 45px 5px 5px 45px;
    flex-wrap: wrap;
    border: 3px solid #fba61b;
}

.column4 {
    flex: 20%; /* Four columns in a row */
    max-width: 25%;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: #ffffff;
    margin: 20px;
    text-align: center;
    border-radius: 5px 45px 45px 5px;
    flex-wrap: wrap;
    border: 3px solid #fba61b;
}

.column2 {
    flex: 20%; /* Four columns in a row */
    max-width: 25%;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: #ffffff;
    margin: 20px;
    text-align: center;
    border-radius: 5px 5px 5px 5px;
    flex-wrap: wrap;
    border: 3px solid #fba61b;
}

.column3 {
    flex: 20%; /* Four columns in a row */
    max-width: 25%;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: #ffffff;
    margin: 20px;
    text-align: center;
    border-radius: 5px 5px 5px 5px;
    flex-wrap: wrap;
    border: 3px solid #fba61b;
}

/* Responsive layout - makes the columns stack on top of each other instead of side by side on small screens */
@media screen and (max-width: 768px) {
    .column1 {
        flex: 50%; /* Two columns in a row */
        max-width: 50%;
    }
    .column2 {
        flex: 50%; /* Two columns in a row */
        max-width: 50%;
    }
    .column3 {
        flex: 50%; /* Two columns in a row */
        max-width: 50%;
    }
    .column4 {
        flex: 50%; /* Two columns in a row */
        max-width: 50%;
    }
}

@media screen and (max-width: 480px) {
    .column1 {
        flex: 100%; /* One column in a row */
        max-width: 100%;
        border-radius: 45px 45px 5px 5px;
        margin: 5px;
    }
    .column2 {
        flex: 100%; /* One column in a row */
        max-width: 100%;
        margin: 5px;
    }
    .column3 {
        flex: 100%; /* One column in a row */
        max-width: 100%;
        margin: 5px;
    }
    .column4 {
        flex: 100%; /* One column in a row */
        max-width: 100%;
        border-radius: 5px 5px 45px 45px;
        margin: 5px;
    }
    .name {
        font-size: 50px;
    }
}

.left-column {
    padding: 30px;
    background: #fba61b;
    border-radius: 10px;
}

.number .counter {
    font-weight: bolder;
    color: #fba61b;
    font-size: 3em;
  }

* {
    box-sizing: border-box;
  }
  
  .column {
    float: left;
    width: 33.33%;
    padding: 5px;
  }
  
  /* Clearfix (clear floats) */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }











  * {
    box-sizing: border-box;
  }
  .image-row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .image-column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .image-column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .image-column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .image-column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }



.main-timeline {
    position: relative
}

.main-timeline:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: #c6c6c6;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.main-timeline .timeline {
    margin-bottom: 40px;
    position: relative
}

.main-timeline .timeline:after {
    content: "";
    display: block;
    clear: both
}

.main-timeline .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.main-timeline .icon:before,
.main-timeline .icon:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.33s ease-out 0s
}

.main-timeline .icon:before {
    background: #fff;
    border: 2px solid #232323;
    left: -3px
}

.main-timeline .icon:after {
    border: 2px solid #c6c6c6;
    left: 3px
}

.main-timeline .timeline:hover .icon:before {
    left: 3px
}

.main-timeline .timeline:hover .icon:after {
    left: -3px
}

.main-timeline .date-content {
    width: 50%;
    float: left;
    margin-top: 22px;
    position: relative
}

.main-timeline .date-content:before {
    content: "";
    width: 36.5%;
    height: 2px;
    background: #c6c6c6;
    margin: auto 0;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0
}

.main-timeline .date-outer {
    width: 125px;
    height: 125px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    z-index: 1
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
    content: "";
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.33s ease-out 0s
}

.main-timeline .date-outer:before {
    background: #fff;
    border: 2px solid #232323;
    left: -6px
}

.main-timeline .date-outer:after {
    border: 2px solid #c6c6c6;
    left: 6px
}

.main-timeline .timeline:hover .date-outer:before {
    left: 6px
}

.main-timeline .timeline:hover .date-outer:after {
    left: -6px
}

.main-timeline .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0
}

.profile-left-column {
    padding: 20px;
    background-color: #fba61b;
    border-radius: 1%;
  }
  
  .profile-item {
    margin-bottom: 1.5rem;
  }
  
  .profile-item h5 {
    font-size: 1rem;
    color: #333;
  }
  
  .social-icons .social-icon {
    margin-right: 10px;
    height: 35px;
    width: 35px;
  }
  

.main-timeline .month {
    font-size: 18px;
    color: #fba61b;
    font-weight: 700
}

.main-timeline .year {
    display: block;
    font-size: 30px;
    font-weight: 700;
    color:#003ecb;
    line-height: 36px
}

.main-timeline .timeline-content {
    width: 50%;
    padding: 20px 0 20px 50px;
    float: right
}

.main-timeline .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 15px 0
}

.main-timeline .description {
    margin-bottom: 0
}

.main-timeline .timeline:nth-child(2n) .date-content {
    float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
    left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 20px 50px 20px 0;
    text-align: right
}

@media only screen and (max-width: 991px) {
    .main-timeline .date-content {
        margin-top: 35px
    }
    .main-timeline .date-content:before {
        width: 22.5%
    }
    .main-timeline .timeline-content {
        padding: 10px 0 10px 30px
    }
    .main-timeline .title {
        font-size: 17px
    }
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding: 10px 30px 10px 0
    }
}

@media only screen and (max-width: 767px) {
    .main-timeline:before {
        margin: 0;
        left: 7px
    }
    .main-timeline .timeline {
        margin-bottom: 20px
    }
    .main-timeline .timeline:last-child {
        margin-bottom: 0
    }
    .main-timeline .icon {
        margin: auto 0
    }
    .main-timeline .date-content {
        width: 95%;
        float: right;
        margin-top: 0
    }
    .main-timeline .date-content:before {
        display: none
    }
    .main-timeline .date-outer {
        width: 110px;
        height: 110px
    }
    .main-timeline .date-outer:before,
    .main-timeline .date-outer:after {
        width: 110px;
        height: 110px
    }
    .main-timeline .date {
        top: 30%
    }
    .main-timeline .year {
        font-size: 24px
    }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        width: 95%;
        text-align: center;
        padding: 10px 0
    }
    .main-timeline .title {
        margin-bottom: 10px
    }
}